.templateBox {
  position: relative;
}

.deleteIcon {
  cursor: pointer;
  color: red;
  font-size: 1.2rem !important;
}
.deleteBox {
  visibility: hidden;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.templateBox:hover .deleteBox {
  visibility: visible;
}
.previewBox a {
  color: initial;
  text-decoration: initial;
}
