@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  line-height: 1.5;
  padding: 0 !important;
  margin: 0 !important;
}

.queryBuilder .ant-select-multiple {
  width: 300px !important; /* Customize width */
}

@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
