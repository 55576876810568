.Container {
  background-color: #fff;
  border-radius: 5px;
}
.loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #2e7d32;
  position: relative;
  animation: flash 1s ease-in-out infinite alternate;
}

.loader::before,
.loader::after {
  content: "";
  position: absolute;
  left: -35px;
  top: 0;
  background: #2e7d32;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transform-origin: 35px -35px;
  transform: rotate(45deg);
  animation: hitL 1s ease-in-out infinite alternate;
}

.loader::after {
  left: 35px;
  transform: rotate(-45deg);
  transform-origin: -35px -35px;
  animation: hitR 1s ease-in-out infinite alternate;
}

@keyframes flash {
  0%,
  100% {
    background-color: #2e7d32; /* Green */
    box-shadow: 17px 0 #2e7d32, -17px 0 #2e7d32; /* Adjusted for 1px spacing */
  }
  25% {
    background-color: #ff8a65; /* Orange */
    box-shadow: 17px 0 #ff8a65, -17px 0 #ff8a65; /* Adjusted for 1px spacing */
  }
  50% {
    background-color: #263238; /* Purple */
    box-shadow: 17px 0 #263238, -17px 0 #263238; /* Adjusted for 1px spacing */
  }
  75% {
    background-color: #ff8a65; /* Orange */
    box-shadow: 17px 0 #ff8a65, -17px 0 #ff8a65; /* Adjusted for 1px spacing */
  }
}

@keyframes hitL {
  0% {
    transform: rotate(45deg);
    background-color: #263238; /* Purple */
  }
  25%,
  100% {
    transform: rotate(0deg);
    background-color: #ff8a65; /* Orange */
  }
}

@keyframes hitR {
  0%,
  75% {
    transform: rotate(0deg);
    background-color: #2e7d32; /* Green */
  }
  100% {
    transform: rotate(-45deg);
    background-color: #2e7d32; /* Green */
  }
}

/* .loader {
  --w: 10ch;
  font-weight: bold;
  font-family: monospace;
  font-size: 30px;
  letter-spacing: var(--w);
  width: var(--w);
  overflow: hidden;
  white-space: nowrap;
  text-shadow: calc(-1 * var(--w)) 0, calc(-2 * var(--w)) 0,
    calc(-3 * var(--w)) 0, calc(-4 * var(--w)) 0, calc(-5 * var(--w)) 0,
    calc(-6 * var(--w)) 0, calc(-7 * var(--w)) 0, calc(-8 * var(--w)) 0,
    calc(-9 * var(--w)) 0;
  animation: l16 2s infinite;
}
.loader:before {
  content: "Loading...";
}
@keyframes l16 {
  20% {
    text-shadow: calc(-1 * var(--w)) 0, calc(-2 * var(--w)) 0 red,
      calc(-3 * var(--w)) 0, calc(-4 * var(--w)) 0 #ffa516,
      calc(-5 * var(--w)) 0 #63fff4, calc(-6 * var(--w)) 0,
      calc(-7 * var(--w)) 0, calc(-8 * var(--w)) 0 green, calc(-9 * var(--w)) 0;
  }
  40% {
    text-shadow: calc(-1 * var(--w)) 0, calc(-2 * var(--w)) 0 red,
      calc(-3 * var(--w)) 0 #e945e9, calc(-4 * var(--w)) 0,
      calc(-5 * var(--w)) 0 green, calc(-6 * var(--w)) 0 orange,
      calc(-7 * var(--w)) 0, calc(-8 * var(--w)) 0 green, calc(-9 * var(--w)) 0;
  }
  60% {
    text-shadow: calc(-1 * var(--w)) 0 lightblue, calc(-2 * var(--w)) 0,
      calc(-3 * var(--w)) 0 #e945e9, calc(-4 * var(--w)) 0,
      calc(-5 * var(--w)) 0 green, calc(-6 * var(--w)) 0,
      calc(-7 * var(--w)) 0 yellow, calc(-8 * var(--w)) 0 #ffa516,
      calc(-9 * var(--w)) 0 red;
  }
  80% {
    text-shadow: calc(-1 * var(--w)) 0 lightblue, calc(-2 * var(--w)) 0 yellow,
      calc(-3 * var(--w)) 0 #63fff4, calc(-4 * var(--w)) 0 #ffa516,
      calc(-5 * var(--w)) 0 red, calc(-6 * var(--w)) 0,
      calc(-7 * var(--w)) 0 grey, calc(-8 * var(--w)) 0 #63fff4,
      calc(-9 * var(--w)) 0;
  }
} */
