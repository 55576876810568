.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .nav-items {
    display: none;
  }

  .nav-more {
    display: block;
  }
}

@media (min-width: 601px) {
  .nav-more {
    display: none;
  }
}
.ant-upload-wrapper {
  position: relative;
}
.ant-upload-wrapper .ant-upload-list {
  position: absolute;
  top: -3px;
  left: 10rem;
  color: #238cbc;
}

.ql-align-center {
  text-align: center;
}
.ql-align-right {
  text-align: right;
}
.ql-align-left {
  text-align: left;
}

.ant-collapse-content {
  border-top: "1px solid rgb(226 232 240)";
}
pre {
  max-width: 100% !important;
  background-color: rgb(243 244 246) !important;
  cursor: pointer;
}

pre code {
  text-wrap-mode: wrap !important;
}
.ruleGroup {
  border-color: rgba(0, 75, 183, 0.2);
}
