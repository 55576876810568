.ant-table-thead .ant-table-cell {
    color: #7D86A9 !important;
  }

  .tblviewicon {
    color: #466685 !important;
    cursor: pointer;
  }

  .tblassignicon {
    color: #CF7530 !important;
    cursor: pointer;
  }

  .tblunshareicon {
    color: #383736 !important;
    cursor: pointer;
  }

  .tbldeleteicon {
    color: #cf2608 !important;
    cursor: pointer;
  }

  .tbldeployicon {
    color: #06A736 !important;
    cursor: pointer;
  }

  .tblcampaignicon {
    color: #1676FF !important;
    cursor: pointer;
  }

  .mergeHelpText {
    color: #9F7647 !important;
    cursor: pointer;  
  }

  /* Custom CSS for the message */
.custom-message {
  font-size: 18px; /* Increase the font size */
  padding: 20px; /* Increase padding for a larger box */  
  font-weight: bold;
}

.custom-message .ant-message-notice-content {
  border-radius: 10px; /* Optional: Make the box rounded */
}

.custom-message .ant-message-custom-content {
  display: flex;
  align-items: center;
}
  